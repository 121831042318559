import Vue from 'vue'
import Router from 'vue-router'

import NotFount from '@/components/404'

Vue.use(Router)

export default new Router({
  routes: [
    
	{
	  path: '*', //其他页面，强制跳转
	  redirect: '/404'
	},
    {
      path: '/404',
      name: '404',
      component: NotFount
    },
    
  ]
})
