import Vue from 'vue'
import App from './App.vue'

import router from './router'

// import store from './store';
// import '@/api/fetch';

// import VueSocketIO from 'vue-socket.io'

// import socketio from 'socket.io-client'
// import VueSocketio from 'vue-socket.io';
// import socketio from 'socket.io-client';

// Vue.use(VueSocketio, socketio('http://192.168.2.101:9099/'));
// Vue.use(new VueSocketIO({
//     debug: true,
//     // http://localhost:8080/hymsg/10
//     connection: 'ws://192.168.2.101:8080',
//     // vuex: {
//     //     store,
//     //     actionPrefix: 'SOCKET_',
//     //     mutationPrefix: 'SOCKET_'
//     // },
//     options: { path: "/hymsg/10" } //Optional options
// }))
// let loginUserNum = "100000";

// Vue.use(new VueSocketIO({
//     debug: true,
//     // http://localhost:8080/hymsg/10
//     // connection: 'http://localhost:9988',
//     // connection: 'http://192.168.2.101:9099',
//     connection: socketio.connect('http://192.168.2.101:9099', {
//       query: 'loginUserNum=' + loginUserNum
//     }),
//     // connection: '/scoket',

//     // // vuex: {
//     // //     store,
//     // //     actionPrefix: 'SOCKET_',
//     // //     mutationPrefix: 'SOCKET_'
//     // // },
//     // options: { loginUserNum: "90" } //Optional options
// }));



Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
