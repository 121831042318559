<template>
  <div class="">
    <h1>
		HYTX.
		<br />NOT FOUND. 
		<br />找不到页面！
	</h1>
    
  </div>
</template>

<script>
export default {
  // name: 'HelloWorld',
  // props: {
  //   msg: String
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h1 {
		padding-top: 30px;
		font-size: 16px;
		line-height: 1.5;
		text-align: center;
	}
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
